import React from "react";
import dynamic from "next/dynamic";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import drupal from "lib/drupal";
import { getGlobals } from "lib/getGlobals";
import Layout from "components/Layout";
import Meta from "components/Meta";
import WordsSlider from "components/WordsSlider";
import getParams from "lib/getParams";

const IntroSection = dynamic(() => import("components/Section/IntroSection"));
const ExpertisesSection = dynamic(() =>
  import("components/Section/ExpertisesSection")
);
const RealizationsSection = dynamic(() =>
  import("components/Section/RealizationsSection")
);
const ClientsSection = dynamic(() =>
  import("components/Section/ClientsSection")
);
const CaseStudiesSections = dynamic(() =>
  import("components/Section/CaseStudiesSection")
);
const ProjectsSection = dynamic(() =>
  import("components/Section/ProjectsSection")
);

export default function IndexPage({ globals, vocabulary, nodes, blocks }) {
  const hasIntro = blocks?.intro;
  const hasExpertisesIntro = blocks?.expertise;
  const hasExpertises = vocabulary?.expertise?.length > 0;
  const hasRealisations = nodes?.realisations?.length > 0;
  const hasClients = nodes?.clients?.length > 0;
  const hasCaseStudies = nodes?.caseStudies?.length > 0;
  const hasProjectCTA = blocks?.projet;

  return (
    <Layout globals={globals} home>
      <Meta
        title="ISICS, agence de développement web et mobile depuis 2004"
        description="Agence web spécialisée dans la création de sites web vitrines et e-commerce sur-mesure, le développement d’applications, le marketing digital et le design UI/UX."
      />

      {hasIntro && <IntroSection intro={blocks.intro} image={blocks.team} />}

      {hasExpertisesIntro && hasExpertises && (
        <ExpertisesSection
          intro={blocks.expertise}
          terms={vocabulary.expertise}
        />
      )}

      <WordsSlider
        words={["engagés", "à l’écoute", "ambitieux", "ingénieux"]}
      />

      {hasRealisations && (
        <RealizationsSection
          nodes={nodes.realisations}
          cta={blocks.realisation}
        />
      )}

      {hasClients && <ClientsSection nodes={nodes.clients} />}

      {hasCaseStudies && <CaseStudiesSections nodes={nodes.caseStudies} />}

      {hasProjectCTA && <ProjectsSection data={blocks.projet} />}
    </Layout>
  );
}

async function getIndexPageBlocks(context) {
  /** Présentation */
  const intro = await drupal.getResource(
    "block_content--basic",
    "332b388c-fbc5-43b1-a75c-0a84dd9a50bd",
    context
  );

  /** Présentation - L'équipe */
  const teamParams = new DrupalJsonApiParams();
  const team = await drupal.getResource(
    "block_content--image",
    "7bef2c55-3067-4bd2-82e4-6395ef4c116c",
    {
      params: teamParams.addInclude(["field_image"]).getQueryObject(),
      ...context,
    }
  );

  /** Réalisations - Appel à projet */
  const realisation = await drupal.getResource(
    "block_content--basic",
    "3cb830bb-7c3b-4527-bfb9-cd6eefabc66d",
    context
  );

  /** Domaines d'expertise - Introduction */
  const expertise = await drupal.getResource(
    "block_content--basic",
    "4fcd0935-16d2-4576-87ec-efee3814e28b",
    context
  );

  /** Appel à projet */
  const projet = await drupal.getResource(
    "block_content--basic",
    "03a013bb-3125-4ff1-a4b2-bd0050862510",
    context
  );

  return {
    intro,
    team,
    expertise,
    realisation,
    projet,
  };
}

export async function getStaticProps(context) {
  const localeContext = {
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  };

  /** Domaines d'expertise */
  const expertise = await drupal.getResourceCollection(
    "taxonomy_term--expertise",
    {
      params: getParams("taxonomy_term--expertise").getQueryObject(),
      ...localeContext,
    }
  );

  /** Réalisations */
  const realisations = await drupal.getResourceCollection("node--realisation", {
    params: getParams("node--realisation")
      .addFilter("field_case_study", 0)
      .addFilter("promote", 1)
      .addSort("sticky", "DESC")
      .addSort("created", "DESC")
      .addPageLimit(3)
      .getQueryObject(),
    ...localeContext,
  });

  /** Clients */
  const clients = await drupal.getResourceCollection("node--client", {
    params: getParams("node--client").getQueryObject(),
    ...localeContext,
  });

  /** Réalisations - Études de cas */
  const caseStudies = await drupal.getResourceCollection("node--realisation", {
    params: getParams("node--realisation")
      .addFilter("field_case_study", 1)
      .addFilter("promote", 1)
      .addSort("sticky", "DESC")
      .addSort("created", "DESC")
      .addPageLimit(3)
      .getQueryObject(),
    ...localeContext,
  });

  /** Blog */
  const articles = await drupal.getResourceCollection("node--article", {
    params: getParams("node--article")
      .addFilter("promote", 1)
      .addSort("sticky", "DESC")
      .addSort("created", "DESC")
      .addPageLimit(3)
      .getQueryObject(),
    ...localeContext,
  });

  return {
    props: {
      globals: await getGlobals(localeContext),
      vocabulary: {
        expertise,
      },
      nodes: {
        realisations,
        caseStudies,
        clients,
        articles,
      },
      blocks: await getIndexPageBlocks(localeContext),
    },
    revalidate: Number(process.env.NEXT_PUBLIC_REVALIDATE) || 60,
  };
}
